<template>
  <div>
    <b-row>
      <b-col>
        <WaitingTable
          :isLoadingProcess="isLoadingProcess"
          :clientsOnHold="clientsOnHold"
          :isLoadingWaitingList="isLoadingWaitingList"
          :showOptionTodos="showOptionTodos"
          class="waiting-list"
          @set-current-consumption-centers="setCurrentConsumptionCenters"
          @reload-data="fetchInfo"
          @set-loading-process="setLoadingProcess"
        />
        <ListCenters
          :isLoadingProcess="isLoadingProcess"
          :holdersToMove="clientsToMove"
          :position="'horizontal'"
          :isLoadingConsumptionCenters="isLoadingConsumptionCenters"
          class="upper-restaurants-list"
          @set-loading-process="setLoadingProcess"
          @fetch-info="fetchInfo"
        />
      </b-col>
      <b-col cols="3">
        <ListCenters
          :isLoadingProcess="isLoadingProcess"
          :holdersToMove="clientsToMove"
          :position="'vertical'"
          :isLoadingConsumptionCenters="isLoadingConsumptionCenters"
          class="restaurants-list"
          @set-loading-process="setLoadingProcess"
          @fetch-info="fetchInfo"
        />
      </b-col>
    </b-row>
  </div>

</template>

<script>
//components
import { mapActions, mapState, mapMutations } from 'vuex'
import * as moment from "moment";
import ListCenters from '@/modules/guestPager/components/ListCenters'
import WaitingTable from '@/modules/guestPager/components/WaitingTable'

export default {
  async created() {
    await this.init()
  },
  components: {
    ListCenters,
    WaitingTable,
  },
  data() {
    return {
      clientsOnHold: [],
      isLoadingWaitingList: false,
      isLoadingConsumptionCenters: false,
      isLoadingProcess: false,
      showOptionTodos: '0'
    }
  },
  computed: {
    ...mapState('guestPager', ['currentConsumptionCenter', 'countryCodes']),
    ...mapState('start', ['hotels', 'languages']),
    ...mapState('appConfig', ['isVerticalMenuCollapsed']),
    clientsToMove(){
      const clients = this.clientsOnHold?.filter(client => client.selected) || []
      return clients
    }
  },
  methods: {
    ...mapActions('guestPager', ['getInitialContentConsuptionsCenters', 'fetchWaitingList', 'fetchWaitingListsCounter']),
    ...mapActions('start', ['getGenericParameter']),
    ...mapMutations('appConfig', ['UPDATE_VERTICAL_MENU_COLLAPSED']),
    ...mapMutations('guestPager', ['setCurrentConsumptionCenter', 'setConsumptionCenters']),
    async init(){
      if (!this.isVerticalMenuCollapsed) this.UPDATE_VERTICAL_MENU_COLLAPSED(true)
      await this.getInitialContentConsuptionsCenters({
        hotels: !this.hotels.length,
        languages: !this.languages.length,
        countryCodes: !this.countryCodes.length,
      })
      const showTodos = await this.getGenericParameter(21)
      this.showOptionTodos = showTodos || '0'
      await this.fetchInfo()
    },
    async fetchInfo(){
      this.isLoadingWaitingList = true
      const waitingList = await this.fetchWaitingList({consumptionCenter: this.currentConsumptionCenter})
      this.clientsOnHold = []
      if (waitingList.length > 0) this.clientsOnHold = waitingList?.map(clients => {
        return{
          ...clients,
          selected: false,
          showNotes: false
        }
      })
      this.isLoadingWaitingList = false

      this.isLoadingConsumptionCenters = true
      const waitingListsCounter = await this.fetchWaitingListsCounter({})
      if (waitingListsCounter.length > 0) this.setConsumptionCenters(waitingListsCounter)
      this.isLoadingConsumptionCenters = false
    },
    async setCurrentConsumptionCenters(data){
      this.setCurrentConsumptionCenter(data)
      await this.fetchInfo()
    },
    setLoadingProcess(bool){
      this.isLoadingProcess = bool
    }
  },
}
</script>

<style>
@media (max-width: 1023px) {
  .restaurants-list{
    display: none;
  }
}

@media (min-width: 1024px) {
  .upper-restaurants-list{
    display: none;
  }
}

@media (max-width: 1280px) {
  .mb-0.table-responsive {
    max-height: 369px;
  }
  .table-responsive th {
    padding: 10px 10px 10px 10px !important;
    font-size: 10px;
  }
  .table-responsive th div {
    font-size: 10px;
  }
  .table-responsive td {
    padding: 5px !important;
    font-size: 11px;
  }
  .buttonInfo button {
    padding: 9px;
  }
  .buttonInfo {
    display: table-cell;
  }
  .isCentered {
    align-content: center;
    align-items: center;
  }
  .isSpinner {
    padding: 2rem;
  }
}
</style>