<template>
  <div>
    <b-card >
      <div class="d-flex justify-content-between">
        <h4 v-if="holdersToMove.length > 0 && currentConsumptionCenter != '0'">Mover a: </h4>
        <h4 v-else>Listas de espera</h4>
          <b-button
            @click="refreshData"
            size="sm"
            variant="primary"
            :disabled="isLoadingProcess"
          > <feather-icon icon="RefreshCwIcon" style="color: white" />
          </b-button>
      </div>
      <hr v-if="position=='vertical'">
      <b-table
        v-if="position=='vertical'"
        :fields="fields"
        :items="consumptionCenters"
        responsive
        fixed
        small
      >

        <template #cell()="data">
          <div class="custom-padding-restaurants">{{ data.value }}</div>
        </template>
        <template #cell(name)="data">
          <div class="custom-padding-restaurants">

            <b-link
              :disabled="isLoadingProcess"
              v-if="holdersToMove.length > 0 && currentConsumptionCenter != '0' && consumptionCenterSihotCode == data.item.hotelSihot"
              @click="moveGuests(data.item)"
              class="font-weight-bolder link-text"
            > {{ data.value }}
            </b-link>

            <span v-else>{{ data.value }}</span>

          </div>
        </template>
      </b-table>

      <b-table
        v-if="position=='horizontal'"
        :items="horizontalFields"
        responsive
        small
        class="text-nowrap mt-1"
      >

        <template #cell()="data">
          <div class="custom-padding-restaurants text-center">

            <b-link
              :disabled="isLoadingProcess"
              v-if="holdersToMove.length > 0 && currentConsumptionCenter != '0' && consumptionCenterSihotCode == data.item.hotelSihot"
              @click="moveGuests(data.item[data.value.name])"
              class="font-weight-bolder link-text"
            > {{ data.value.waiters }}
            </b-link>

            <span v-else>{{ data.value.waiters }}</span>

          </div>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { sweetAlert } from '@/helpers/helpers'

export default {
  props: {
    position:{
      type: String,
      required: true
    },
    holdersToMove: {
      type: Array,
      required: true,
      default: []
    },
    isLoadingConsumptionCenters:{
      type: Boolean,
      required: true
    },
    isLoadingProcess: {
      type: Boolean,
      required: true
    }
  },
  data(){
    return{
      fields: [
        { key:'name', label: 'Centro de consumo', class: 'custom-padding-restaurants'},
        { key:'waiters', label: 'En espera', class: 'custom-padding-restaurants text-center'},
        // { key:'waitingStart', label: 'Tiempo de espera', class: 'custom-padding-restaurants text-center', formatter: 'getAverageWaitingTime'},
      ]
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('guestPager', ['consumptionCenters', 'currentConsumptionCenter']),
    horizontalFields(){
      const fields = {}
      for (let idx = 0; idx < this.consumptionCenters.length; idx++) {
        const restaurant = this.consumptionCenters[idx]
        fields[restaurant.name] = {
          ...restaurant
        }
      }

      return [fields]
    },
    consumptionCenterSihotCode(){
      const data = this.consumptionCenters?.find(cc => cc.id == this.currentConsumptionCenter)
      return data?.hotelSihot || null
    }
  },
  methods: {
    ...mapActions('guestPager', ['releaseGuest']),
    async moveGuests(data){
      if (this.holdersToMove.length > 0){
        const swalert = {
          title: `Moviendo ${this.holdersToMove.length} clientes a ${data.name}`,
          message: "¿Desea continuar?",
          icon: 'info',
          confirmButton: "Continuar",
          cancelButton: "Cancelar",
        }

        const {isConfirmed} = await sweetAlert(swalert)
        if ( isConfirmed ) {
          this.$emit('set-loading-process', true)
          const response = await this.releaseGuest({status: 4, consumptionCenter: data.id, idUser: this.user.idUser, ids: this.holdersToMove.map(guest => guest.id)})
          if (response) showAlertMessage('Ok', 'InfoIcon', 'Cliente movido a la lista de espera', 'success')
          this.$emit('set-loading-process', false)
          this.$emit('fetch-info')
        }
      }
    },
    getAverageWaitingTime(data){
      return '0'
    },
    refreshData(){
      this.$emit('fetch-info')
    },
  }

}
</script>

<style>
.custom-padding-restaurants{
  padding: 0.53rem 0 !important;
}
.link-text{
  font-size: 1rem;
  text-decoration: underline solid 1.5px !important;
}
</style>