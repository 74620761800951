<template>
  <b-card>
    <b-row align-v="center" align-h="center">
      <b-col cols="4" class="mb-1 text-nowrap">
        <label>Centro de Consumo</label> <br>
        <b-form-select
          v-model="selectedConsumptionCenter"
          @change="setCenter(selectedConsumptionCenter)"
          class="w-75"
          :clearable="false"
        >
          <option :value="null">Seleccione una opción</option>
          <option value="0" v-if="showOptionTodos == '1'">Todos</option>
          <option v-for="c in consumptionCenters" :key="c.id" :value="c.id" :class="c.selected ? 'font-weight-bolder':''"> {{ c.name }}</option>
        </b-form-select>
        <b-button
          v-if="CChasGuests"
          type="button"
          class="fives-club-excel-btn ml-1"
          :disabled="!selectedConsumptionCenter || isLoadingSomething || isDowloadingExcel"
          @click="getExcel"
        > <b-spinner small label="Loading..." v-if="isDowloadingExcel"/> <feather-icon icon="FileTextIcon" v-else/>
        </b-button>
      </b-col>
      <b-col class="text-right">
        <b-button
          v-if="canEditCustomer"
          type="button"
          variant="primary"
          class="btn-list-actions"
          :disabled="isLoadingProcess"
          :to="{name: 'clients-waiting-register'}"
        > <feather-icon icon="PlusIcon" size="12" />
          <span class="align-middle ml-50">Añadir</span>
        </b-button>
      </b-col>
      <b-col cols="12"/>

      <b-col cols="6" class="d-flex justify-content-between mt-1">

        <b-button
          v-if="canEditCustomer"
          type="button"
          variant="primary"
          class="d-none btn-list-actions"
          @click="sendNotification"
          :disabled="!dataSelected.length || isLoadingSomething"
        > <b-spinner small v-if="isSendingNotification"/> <feather-icon v-else icon="MessageCircleIcon" />
          <span class="align-middle ml-50">Notificar</span>
        </b-button>

        <b-button
          v-if="canEditCustomer"
          type="button"
          class="btn-list-actions"
          variant="success"
          @click="releaseInGuest(2)"
          :disabled="!dataSelected.length || isLoadingSomething"
        > <b-spinner small v-if="isUpdatingStatus == 2"/> <feather-icon icon="CheckIcon" size="12" v-else/>
          <span class="align-middle ml-50">Check-in</span>
        </b-button>

        <b-button
          v-if="canEditCustomer"
          type="button"
          class="btn-list-actions"
          variant="danger"
          @click="releaseInGuest(3)"
          :disabled="!dataSelected.length || isLoadingSomething"
        > <b-spinner small v-if="isUpdatingStatus == 3"/> <feather-icon icon="XIcon" size="12" v-else/>
          <span class="align-middle ml-50">Cancelar</span>
        </b-button>

      </b-col>

      <b-col cols="12" class="mt-1" v-if="!!selectedConsumptionCenter && clientsOnHold.length">
        <b-table
          :fields="consumptionCenterTable"
          :items="clientsOnHold"
          fixed
          responsive
          small
          :busy.sync="isLoadingWaitingList"
          sticky-header="400px"
          class="waiting-table"
        >

          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner> <br>
              <strong>Cargando Lista de espera</strong>
            </div>
          </template>

          <template #head(select)="">
            <b-button v-if="clientsOnHold.length > 0 && canEditCustomer" variant="link" size="sm" class="custom-padding-waiting-list" @click="selectAllRows()" >
              <feather-icon size="14" :icon="allSelected ? 'CheckCircleIcon' : 'CircleIcon'" class="" />
            </b-button>
            <span v-else> </span>
          </template>

          <template #cell(timesNotificated)="data">
            <b-button :id="`popover-guest-hold-${data.item.id}`" size="sm" variant="link">
              {{ data.value.length }} <feather-icon icon="MessageCircleIcon" />
            </b-button>
            <b-popover v-if="data.value.length>0" :target="`popover-guest-hold-${data.item.id}`" triggers="hover" placement="top">
              <template #title>Notificaciones enviadas</template>
              <ul>
                <li v-for="(notification, index) in data.value" :key="index" class="mb-1">
                  {{ formatThisDate(notification.createDate) }} <br>
                  <span class="text-success" v-if="notification.status == 5"> Notificado con exito</span>
                  <span v-else>Notificacion enviada</span>
                </li>
              </ul>
            </b-popover>
          </template>

          <template #cell(notes)="data">
            <div @click="toggleNotes(data.item)" class="clickable">
              <span v-if="!data.item.showNotes && data.value.length>50"> {{ data.value.slice(0, 50) }}...</span>
              <span v-else>{{ data.value }}</span>
            </div>
          </template>

          <template #cell(room)="data">
            <b-link
              :to="{name: 'clients-waiting-edit', params: { id: data.item.id }}"
              class="font-weight-bolder link-text"
            > {{ data.value }} </b-link>
          </template>

          <template #cell(select)="row">

            <div class="text-center">
              <!-- <b-form-checkbox
                v-model="row.item.selected"
                inline
                :value="true"
                :unchecked-value="false"
              ></b-form-checkbox> -->
              <b-button variant="link" size="sm" class="custom-padding-waiting-list" @click="row.item.selected = !row.item.selected" v-if="canEditCustomer">
                <feather-icon size="14" :icon="row.item.selected ? 'CheckCircleIcon' : 'CircleIcon'" class="" />
              </b-button>
            </div>

          </template>
        </b-table>
      </b-col>
      <b-col cols="12" class="mt-1 text-center" v-else-if="!selectedConsumptionCenter">
        <b-alert show variant="warning" class="p-1"> Seleccione un centro de consumo para continuar</b-alert>
      </b-col>
      <b-col cols="12" class="mt-1 text-center" v-else-if="!clientsOnHold.length">
        <b-alert show variant="success" class="p-1"> No hay clientes en espera</b-alert>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import * as moment from "moment";
import Ripple from 'vue-ripple-directive'
import { formatTime, sweetAlert ,formatTimeWithMilliseconds, showAlertMessage } from '@/helpers/helpers';
import { acl } from "@/modules/auth/mixins/acl"

export default {
  mixins: [acl],
  directives: {
    Ripple,
  },
  async created(){
    await this.init()
  },
  props: {
    clientsOnHold:{
      type: Array,
      required: true
    },
    isLoadingWaitingList:{
      type: Boolean,
      required: true
    },
    isLoadingProcess: {
      type: Boolean,
      required: true
    },
    showOptionTodos: {
      type: String,
      required: true
    }
  },
  data(){
    return{
      selectedConsumptionCenter: null,
      // currentConsumptionCenter: null,
      isUpdatingStatus: false,
      isSendingNotification: false,
      isDowloadingExcel: false,
      waitingListFields: [
        { key:'select', label: '', class: 'text-center select-row'},
        { key:'room', label: 'Habitación', class:'text-nowrap text-center custom-padding-table w-25'},
        { key:'guestName', label: 'Titular', class: 'custom-padding-table w-25'},
        { key:'pax', label: 'Pax', class: 'custom-padding-table w-25 custom-width'},
        // { key:'timesNotificated', label: 'Notificaciones', class: 'custom-padding-table w-25'},
        { key:'waitingStartDate', label: 'Fecha', class: 'custom-padding-table w-25'},
        { key:'waitingStartTime', label: 'Hora', class: 'custom-padding-table w-25', formatter: value => {
          return formatTime({time: value, format: 'HH:mm'})
        }},
        { key:'waitingTime', label: 'Tiempo de espera', class: 'custom-padding-table w-25', formatter: (value, key, item) => {
          return this.getTime(item)
        }},
        { key:'notes', label: 'Notas', class: 'custom-padding-table w-50 cut-notes'},
      ]
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('guestPager', ['currentConsumptionCenter', 'consumptionCenters']),
    allSelected(){
      const selectedItems = this.clientsOnHold.filter(item => item.selected)
      const data = selectedItems.length == this.clientsOnHold.length
      return data
    },
    dataSelected(){
      const someSelected = this.clientsOnHold.filter(item => item.selected)
      if (someSelected.length>0) return someSelected.map(data => data.id)
      return []
    },
    isLoadingSomething(){
      return this.isUpdatingStatus || this.isSendingNotification || this.isLoadingProcess
    },
    canEditCustomer(){
      return this.can('consumption_centers_waiting_list_show_waiting_list_buttons')
    },
    CChasGuests(){
      let hasValidGuests = false
      if (this.selectedConsumptionCenter == '0') {
        hasValidGuests = this.consumptionCenters.some(cc => cc.todaysGuests > 0)
      } else {
        const consuptioncenter = this.consumptionCenters.find(cc => cc.id == this.selectedConsumptionCenter)
        hasValidGuests = consuptioncenter?.todaysGuests > 0
      }
      return hasValidGuests
    },
    consumptionCenterTable(){
      let waitingList = this.waitingListFields
      if (this.selectedConsumptionCenter == '0'){
        waitingList = waitingList.slice(1)
        waitingList = [].concat([
          { key:'select', label: '', class: 'text-center select-row'},
          {key:'consumptionCenterName', label: 'Centro de consumo', class: 'custom-padding-table w-25 font-weight-bolder'}
        ], waitingList)
      }
      return waitingList
    },
  },
  methods: {
    ...mapActions('guestPager', ['releaseGuest', 'notifyGuest', 'fetchWaitingListReport']),
    // ...mapMutations('guestPager', ['']),
    async init() {
      if (!!this.currentConsumptionCenter) this.selectedConsumptionCenter = structuredClone(this.currentConsumptionCenter)
      if (this.showOptionTodos == '0' && this.selectedConsumptionCenter == '0') {
        this.selectedConsumptionCenter = null
      }
    },
    goToEdition(id){
      this.$router.push({name: 'clients-waiting-edit', params: {id}})
    },
    async releaseInGuest(status){
      this.isUpdatingStatus = status
      this.$emit('set-loading-process', true)
      if (this.dataSelected.length > 0){
        if (this.dataSelected.length > 1){
          const swalert = {
            title: `Liberando ${this.dataSelected.length} clientes`,
            message: "¿Desea continuar?",
            icon: 'info',
            confirmButton: "Continuar",
            cancelButton: "Cancelar",
          }

          const {isConfirmed} = await sweetAlert(swalert)
          if ( isConfirmed ) {
            const response = await this.releaseGuest({status, ids: this.dataSelected, idUser: this.user.idUser})
            if (response) showAlertMessage('Ok', 'InfoIcon', 'Clientes liberados', 'success')
          }
        } else {
          const response = await this.releaseGuest({status, ids: this.dataSelected, idUser: this.user.idUser})
          if (response) showAlertMessage('Ok', 'InfoIcon', 'Cliente liberado', 'success')
        }
      }

      this.refreshData()
      this.$emit('set-loading-process', false)
      this.isUpdatingStatus = false
    },
    getTime(data){
      const time = moment(`${data.waitingStartDate} ${data.waitingStartTime}`, 'YYYY-MM-DD HH:mm')
      const waitingTime = moment().diff(time)
      const timeWaited = formatTimeWithMilliseconds(waitingTime)
      if (timeWaited.hh) return `${timeWaited.hh} hrs. ${timeWaited.mm} min.`
      return `${timeWaited.mm} min.`
    },
    setCenter(data){
      this.$emit('set-current-consumption-centers', data)
    },
    refreshData(){
      this.$emit('reload-data')
    },
    formatThisDate(value){
      const date = moment(value).format('YYYY-MM-DD | hh:mm A')
      return date
    },
    selectAllRows(){
      const value = !this.allSelected
      this.clientsOnHold.forEach(D => {
        D.selected = value
      })
    },
    toggleNotes(item){
      item.showNotes = !item.showNotes
    },
    async sendNotification(){
      this.isSendingNotification = true
      this.$emit('set-loading-process', true)
      if (this.dataSelected.length > 0){
        const response = await this.notifyGuest({ids: this.dataSelected, idUser: this.user.idUser})
        showAlertMessage('Ok', 'InfoIcon', response.message, 'success', 4000)
        this.refreshData()
      }
      this.isSendingNotification = false
      this.$emit('set-loading-process', false)
    },
    async getExcel(){
      this.isDowloadingExcel = true
      const payload = {
        consumptionCenter: this.selectedConsumptionCenter,
        excel: true
      }
      await this.fetchWaitingListReport(payload)
      this.isDowloadingExcel = false
    },
    async showNotes(data){
      const selectedItem = this.clientsOnHold.filter(item => item.id == data)[0]
      const h = this.$createElement

      const messageVNode = h('div', { class: ['foobar'] }, [
        h('p', { class: ['text-center mb-1'] }, [
          selectedItem.notes,
        ]),

      ])

			const modal = await this.$bvModal.msgBoxOk([messageVNode],{
				title: `${selectedItem.guestName} | ${selectedItem.room}`,
				size: 'sm',
				centered: true,
				hideHeaderClose: false,
			})

			return modal
    },
  }

}
</script>

<style>
.custom-padding-table{
  padding: 0.65rem !important;
}
.dropdown-waiting-list{
  position: absolute !important;
}
.waiting-table{
  height: 400px;
}
.select-row{
  width: 3rem;
}
.custom-padding-waiting-list{
  padding: 0.4rem;
}
.custom-width{
  width: 4rem !important;
}
.link-text{
  text-decoration: underline solid 1.5px !important;
}
.btn-list-actions{
  padding: 0.6rem 1.1rem;
}
</style>